import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Icon,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AffiliatedIcon,
  ClaimsDateProvider,
  LoadingView,
  MarketOrTagSelect,
  Paginateable,
  TaxonomySelect,
  TitleTooltip,
} from 'components';
import { StoreUtil } from 'doctivity-shared/utils';
import { MarketsUtil } from 'utils';
import PropTypes from 'prop-types';
import { listMarkets } from 'store/actions/marketsActions';
import { withStyles } from '@mui/styles';
import { PayorSearch, useReportCriteria } from 'market-data';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { TagsUtil } from '../utils';
import { listTagNamespacesByClient } from 'store/actions/tagNamespacesActions';
import fileDownload from 'js-file-download';
import { CsvUtil, DateUtil } from 'doctivity-shared/utils';
import { ColorUtil } from 'utils';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dropDownContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
  },
  select: {
    width: '100%',
    height: 57,
  },
  filterSecondRow: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexBasis: '100%',
    gap: theme.spacing(2),
  },
  chartDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  chartData: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    textDecoration: 'none',
  },
  chartDataName: {
    width: '100%',
    position: ' absolute',
    top: 0,
    left: 0,
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    mixBlendMode: ' screen',
    zIndex: ' 1',
    '& .material-icons': {
      fontSize: 10,
      color: 'white',
      position: 'absolute',
      top: 5,
      left: 8,
    },
  },
  chartDataNameLabel: {
    marginLeft: theme.spacing(3),
    display: 'inline-block',
    maxWidth: '16rem',
    overflowX: 'hidden',
    fontSize: 12,
    fontFamily: 'sans-serif',
    position: 'relative',
    top: 2,
  },
  chartPoint: {
    textAlign: 'center',
    color: 'white',
  },
  legend: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    flexDirection: 'row',
    gap: theme.spacing(4),
    justifyContent: 'flex-start',
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  legendRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  legendItem: {
    height: 14,
    width: 14,
    borderRadius: 7,
    display: 'inline-block',
    fontSize: 14,
  },
  legendItemLabel: {
    overflowX: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    backgroundColor: 'white',
    color: 'black',
    display: 'inline-block',
    paddingLeft: theme.spacing(1),
    fontSize: 14,
  },
  chartDataMetrics: {
    position: 'absolute',
    left: '20rem',
  },
  noData: {
    textAlign: 'center',
    paddingTop: 50,
    minHeight: 200,
    fontSize: 16,
  },
  tooltipHeader: {
    backgroundColor: theme.palette.tertiary.main,
    padding: theme.spacing(1),
    color: theme.palette.tertiary.accent,
    border: `1px solid ${theme.palette.tertiary.accent}`,
    borderBottom: `1px solid ${theme.palette.tertiary.secondary}`,
    boxShadow: theme.shadows[1],
    borderRadius: '5px 5px 0px 0px',
  },
  tooltipBody: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.tertiary.accent,
    border: `1px solid ${theme.palette.tertiary.accent}`,
    borderTop: 'none',
    boxShadow: theme.shadows[1],
    borderRadius: '0px 0px 5px 5px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    fontSize: 12,
  },
  tooltipPercent: {
    fontWeight: 600,
  },
  tooltipLegendContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoTipIcon: {
    position: 'relative',
    top: -4,
    marginLeft: theme.spacing(),
    fontSize: 14,
    fontWeight: 200,
    color: '#999999',
  },
  infoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
});
const WhiteTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(0,0,0,0)',
    color: '#000',
    fontSize: 14,
    fontWeight: 400,
    maxWidth: '25rem',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    minWidth: '15rem',
  },
}))(Tooltip);
const colors = [...ColorUtil.COLORS, '#e3e3e3'];
function PayorChart({ classes }) {
  const dispatch = useDispatch();
  const onExportClick = useCallback(() => {
    const data = claims?.data?.rows.map((claim) => {
      return [
        claim.provider.npi,
        claim.provider.last_name,
        claim.provider.first_name,
        claim.provider.clients?.length > 0 ? 'Affiliated' : 'Non-Affiliated',
        (claim.percent_0 ?? 0)?.toFixed(2),
        (claim.percent_1 ?? 0)?.toFixed(2),
        (claim.percent_2 ?? 0)?.toFixed(2),
        (
          100 -
          ((claim.percent_0 ?? 0) +
            (claim.percent_1 ?? 0) +
            (claim.percent_2 ?? 0))
        ).toFixed(2),
      ];
    });
    data.unshift([
      'NPI',
      'Last Name',
      'First Name',
      'Affiliation',
      ...loadedPayors?.map((p) => p.name),
      'Other',
    ]);
    const date = DateUtil.formatDateForUser(new Date());
    fileDownload(
      CsvUtil.matrixToString(data),
      `doctivity_PROVIDER_PERCENT_PAYOR_${date}.csv`,
    );
  });
  const debounceTimeout = useRef(null);
  const [loadedPayors, setLoadedPayors] = useState([]);
  const [singlePayor, setSinglePayor] = useState(false);

  const markets = useSelector((state) => MarketsUtil.getMarkets(state));
  const tagNamespaces = useSelector((state) =>
    StoreUtil.get(state.tagNamespaces, 'API_TAG_NAMESPACES_LIST_BY_CLIENT'),
  );
  const { getCriteria, updateCriteria, current } = useReportCriteria('payor');
  const { ready } = current;
  const [marketOrTagFilter, setMarketOrTagFilter] = useState({});
  const clientId = useSelector((state) => state.app.selectedClient);
  const claims = useSelector((state) =>
    StoreUtil.get(state.analytics, 'CLAIMS_PROVIDER_BY_PAYOR'),
  );
  const [query, setQuery] = useState({
    offset: 0,
    limit: 25,
  });
  useEffect(() => {
    dispatch(listMarkets(clientId));
    if (!tagNamespaces) {
      dispatch(listTagNamespacesByClient(clientId));
    }
  }, [clientId]);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    if (!current.ready) {
      return;
    }
    debounceTimeout.current = setTimeout(() => {
      const criteria = getCriteria();
      if (
        !criteria ||
        !criteria.affiliated_client_id ||
        !(current.market || current.tag) ||
        !criteria.payors.length
      ) {
        return;
      }
      const filter = {
        ...(criteria.isMarket
          ? { market: { id: current.market } }
          : { tag: { id: current.tag } }),
        affiliated_client_id: criteria.affiliated_client_id,
        payors: criteria.payors,
        taxonomies: criteria.taxonomies,
        affiliation: criteria.affiliation,
        singlePayor,
      };
      dispatch(
        loadAnalytics({
          type: 'CLAIMS_PROVIDER_BY_PAYOR',
          filter,
          opts: query,
        }),
      );
    }, 100);
  }, [current, getCriteria, query, singlePayor]);
  useEffect(() => {
    if (current.ready) {
      // if we have markets loaded, payors loaded, but do not have a market or tag
      // then we need to default all of the state to something reasonable
      if (
        current &&
        !(current.market || current.tag) &&
        StoreUtil.isLoaded(markets) &&
        current.payors &&
        current.payors.length
      ) {
        updateCriteria({
          market: MarketsUtil.getDefaultMarket()?.id,
          isMarket: true,
        });
      }
    }
  }, [
    StoreUtil.isLoaded(markets),
    current?.payors,
    current?.ready
  ]);
  useEffect(() => {
    if (current?.isMarket !== undefined && current.ready) {
      if (current.isMarket) {
        if (!marketOrTagFilter?.market && markets?.data) {
          setMarketOrTagFilter({
            type: 'market',
            market: markets.data.find((m) => m.id === current.market),
          });
        }
      } else {
        if (!marketOrTagFilter?.tag && StoreUtil.isLoaded(tagNamespaces)) {
          setMarketOrTagFilter({
            type: 'tag',
            tag: TagsUtil.findTag(current.tag, tagNamespaces),
          });
        }
      }
    }
  }, [current, markets, marketOrTagFilter]);
  const data = claims?.data?.rows ?? [];
  const legendOrder = current?.payors ?? [];
  const payorMap = loadedPayors?.reduce((acc, payor, i) => {
    acc[payor.id] = { name: payor.name, color: colors[i] };
    return acc;
  }, {});
  if (!current.ready) {
    return <LoadingView />;
  }
  return (
    <div className={classes.container}>
      <ClaimsDateProvider />
      <Card>
        <CardContent>
          <TitleTooltip
            title='Market Data'
            tooltip={
              "Compare up to 3 payors across a filtered list of providers. Rollover each provider to see the % per payor, including the remaining % with ’Other payor’s.' Click into a provider to see all payors on the payor pie chart."
            }
            rightLabel={null && 'Based on claims from all ages'}
            onExport={onExportClick}
          />
          <div className={classes.filterContainer}>
            <div className={classes.dropDownContainer}>
              <MarketOrTagSelect
                filter={marketOrTagFilter}
                onChange={(filter) => {
                  if (!ready) return;
                  setQuery({ ...query, offset: 0 });
                  updateCriteria({
                    market: filter?.market?.id,
                    tag: filter?.tag?.id,
                    isMarket: filter?.type === 'market',
                  });
                }}
                allowTagSelection={true}
                markets={markets}
                market={marketOrTagFilter?.market}
                type='providers'
              />
              <TaxonomySelect
                onChange={(taxonomies) => {
                  if (!ready) return;
                  let newTaxonomies = [];
                  
                  taxonomies.map((ele) => {
                    newTaxonomies.push({'id': ele.code});
                  });
                  setQuery({ ...query, offset: 0 });
                  updateCriteria({ taxonomies: newTaxonomies });
                }}
                values={current.taxonomies}
              />
              <FormControl
                variant='outlined'
                className={classes.dropDownContainer}
                style={{ width: 168 }}
                key='affiliated'
              >
                <InputLabel className={classes.filterLabel}>
                  Affiliation
                </InputLabel>
                <Select
                  key='select'
                  value={current.affiliation}
                  onChange={(event) => {
                    if (!ready) return;
                    setQuery({ ...query, offset: 0 });
                    updateCriteria({ affiliation: event.target.value });
                  }}
                  className={classes.select}
                  label='Affiliation'
                >
                  <MenuItem value='all' key='all'>
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value='affiliated' key='affiliated'>
                    Affiliated
                  </MenuItem>
                  <MenuItem value='non-affiliated' key='non-affiliated'>
                    Non-Affiliated
                  </MenuItem>
                </Select>
              </FormControl>
              <div className={classes.filterSecondRow}>
                <PayorSearch
                  initialNames={['MEDICARE', 'MEDICAID']}
                  limit={3}
                  ready={ready}
                  onPayorsChanged={(payors) => {
                    if (payors.length < 1) {
                      return;
                    }
                    setLoadedPayors(payors);
                    setQuery({ ...query, offset: 0 });
                    updateCriteria({ payors: payors.map((p) => p.id) });
                  }}
                  payorIds={current?.payors}
                />
                <FormControlLabel
                  value='end'
                  label={
                    <>
                      Show providers with single payor
                      <Tooltip
                        placement='bottom-start'
                        title={
                          <Typography className={classes.infoTip}>
                            Show providers with a single payor, will display
                            providers that have only a single payor for 100% of
                            their claims. These are usually smaller volume
                            providers.
                          </Typography>
                        }
                      >
                        <Icon className={classes.infoTipIcon}>
                          info_outlined
                        </Icon>
                      </Tooltip>
                    </>
                  }
                  control={
                    <Checkbox
                      key='singlePayor'
                      checked={singlePayor}
                      onChange={(event) => {
                        if (!ready) return;
                        setSinglePayor(event.target.checked);
                        setQuery({ ...query, offset: 0 });
                      }}
                      label='Show Providers with Single Payor'
                    />
                  }
                />
              </div>
            </div>
          </div>
          {StoreUtil.isLoading(claims) && <LoadingView />}
          {!StoreUtil.isLoading(claims) && claims?.data?.rows?.length > 0 && (
            <div className={classes.legend}>
              {legendOrder.map((i) => {
                const payor = payorMap[i];
                if (!payor) return;
                return (
                  <div key={i} className={classes.legendRow}>
                    <span
                      style={{ backgroundColor: payor?.color }}
                      className={classes.legendItem}
                    >
                      &nbsp;
                    </span>
                    <span className={classes.legendItemLabel}>
                      {payor.name}
                    </span>
                  </div>
                );
              })}

              <div key='other' className={classes.legendRow}>
                <span
                  style={{
                    backgroundColor: colors[colors.length - 1],
                    border: '1px solid #DFDBDB',
                    width: 12,
                    height: 12,
                  }}
                  className={classes.legendItem}
                >
                  &nbsp;
                </span>
                <span className={classes.legendItemLabel}>Other Payors</span>
              </div>

              <div style={{ flex: 1 }} />
            </div>
          )}
          {claims &&
          StoreUtil.isLoaded(claims) &&
          claims?.data?.rows?.length > 0 ? (
            <div className={classes.chartDataContainer}>
              {data.map((claim) => {
                let total =
                  (claim.percent_0 ?? 0) +
                  (claim.percent_1 ?? 0) +
                  (claim.percent_2 ?? 0);
                const result = loadedPayors.map((payor, i) => (
                  <div
                    key={`${payor.id}-${i}`}
                    style={{
                      width: `${claim[`percent_${i}`]}%`,
                      minWidth: `${claim[`percent_${i}`]}%`,
                      position: 'relative',
                      backgroundColor: payorMap[payor.id]?.color,
                    }}
                    className={classes.chartPoint}
                  >
                    &nbsp;
                  </div>
                ));
                const title = (
                  <>
                    <span className={classes.tooltipHeader}>
                      {claim.provider.last_name}, {claim.provider.first_name}
                    </span>
                    <div className={classes.tooltipBody}>
                      {loadedPayors.map((payor, i) => (
                        <div
                          key={payor.id}
                          className={classes.tooltipLegendContainer}
                        >
                          <div
                            key={`${payor.id}-${i}`}
                            style={{
                              width: 14,
                              minWidth: 14,
                              height: 14,
                              borderRadius: 7,
                              marginRight: 8,
                              position: 'relative',
                              backgroundColor: payorMap[payor.id]?.color,
                            }}
                            className={classes.chartPoint}
                          >
                            &nbsp;
                          </div>
                          {payorMap[payor.id]?.name}:&nbsp;
                          <span className={classes.tooltipPercent}>
                            {(claim[`percent_${i}`] ?? 0)?.toFixed(2)}%
                          </span>
                        </div>
                      ))}
                      <div
                        key={`${claim.provider.id}-other`}
                        className={classes.tooltipLegendContainer}
                      >
                        <div
                          style={{
                            width: 12,
                            minWidth: 12,
                            height: 12,
                            borderRadius: 7,
                            marginRight: 8,
                            position: 'relative',
                            backgroundColor: colors[colors.length - 1],
                            border: '1px solid #DFDBDB',
                          }}
                          className={classes.chartPoint}
                        >
                          &nbsp;
                        </div>
                        Other:&nbsp;
                        <span className={classes.tooltipPercent}>
                          {Math.min(100, Math.max(0, 100 - total)).toFixed(2)}%
                        </span>
                      </div>
                    </div>
                  </>
                );

                return (
                  <WhiteTooltip
                    key={`tooltip-${claim.provider_id}`}
                    arrow
                    followCursor
                    title={title}
                  >
                    <Link
                      className={classes.chartData}
                      style={{ backgroundColor: colors[colors.length - 1] }}
                      key={`pi-${claim.provider_id}`}
                      to={`/providers/${claim.provider_id}/market`}
                    >
                      <div className={classes.chartDataName}>
                        {claim.provider.clients.length > 0 && (
                          <AffiliatedIcon type='Provider' />
                        )}
                        <span className={classes.chartDataNameLabel}>
                          {claim.provider.last_name},&nbsp;
                          {claim.provider.first_name}
                        </span>
                      </div>
                      {result}
                    </Link>
                  </WhiteTooltip>
                );
              })}
              <div className={classes.pagination}>
                <Paginateable
                  data={claims?.data}
                  query={query}
                  onQueryChange={(query) => setQuery(query)}
                />
              </div>
            </div>
          ) : (
            !StoreUtil.isLoading(claims) && (
              <div className={classes.noData}>No data available</div>
            )
          )}
        </CardContent>
      </Card>
    </div>
  );
}

PayorChart.propTypes = {
  classes: PropTypes.object,
};
const styled = withStyles(styles)(PayorChart);
export { styled as PayorChart };
